$width-of-preview: 22.5rem;
$font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Arial, sans-serif;
$color-background: #202020;
$color-background-button: unquote('#ffffff4d');
$color-white: #fff;
$color-black: #000;
$color-text: #a7a7a7;
$color-text-header: #555;
$color-text-button: #333;
$color-shadow: #d4d4d5;
$color-border: #ddd;

.ios {
  color: $color-black;
  font-family: $font-family;
  font-weight: 300;
  width: $width-of-preview;

  .main {
    background: $color-white;
    border: 0;
    border-radius: 0.8125rem;
    box-shadow: $color-shadow 0 0.0625rem 0.1875rem 0;
    cursor: pointer;
    padding: 0;

    .header {
      color: $color-text-header;
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      padding: 0.625rem 0.625rem 0.5rem;

      .app-name {
        text-transform: uppercase;
      }
    }

    .close-btn {
      cursor: pointer;
      font-size: 1rem;
      font-weight: normal;
      transform: scale(1.3, 1);
    }

    .section {
      display: flex;
      justify-content: space-between;
      padding: 0.5rem 0.625rem 0.625rem;
    }

    .title {
      font-size: 0.875rem;
      margin-bottom: 0.25rem;
    }

    .header > * {
      display: flex;
    }

    .section > * {
      display: flex;
    }

    .short-description {
      flex-direction: column;
      font-size: 0.9375rem;
      max-width: calc(100% - 3.125rem);

      .message {
        line-height: 1.1875rem;
      }

      .title,
      .subtitle {
        font-weight: 500;
        line-height: 1.1875rem;
      }
    }

    .image {
      flex: 1;

      img {
        max-width: 100%;
      }
    }

    .image-preview-small {
      height: 2.25rem;
      min-width: 2.25rem;
      width: 2.25rem;

      img {
        border-radius: 0.25rem;
        height: 100%;
        width: 100%;
      }
    }

    .icon {
      align-items: center;
      display: flex;
      margin-right: 0.3125rem;

      img {
        max-height: 1.25rem;
        max-width: 1.25rem;
      }
    }
  }

  &.expanded {
    .header {
      align-items: center;
      font-size: 0.8125rem;
      padding: 0.875rem;
    }

    .section {
      padding: 0.875rem;
    }
  }

  .buttons {
    background: $color-background-button;
    border-radius: 0.8125rem;
    box-shadow: $color-shadow 0 0.0625rem 0.1875rem 0;
    margin-top: 0.625rem;
    text-align: center;

    .button {
      border-bottom: 0.0625rem solid $color-border;
      color: $color-text-button;
      cursor: pointer;
      padding: 0.9375rem;

      span {
        @include truncate;
      }

      &:last-child {
        border: 0;
      }
    }
  }
}
