@import "./../_mixins";

$color-background: #fff;
$color-border: #eee;
$color-button: #666;
$color-control: #ccc;
$color-empty: #888;
$color-error: #f5365c;
$color-input: #ddd;
$color-label-text: #fff;
$color-label: #f00;
$color-link: #675df8;
$color-scrollbar: #b3bbbf;
$color-shadow: rgba(0, 0, 0, 0.2);
$color-success: #2dce89;
$color-text: #222;

// Add everything inside notifo to avoid conflicts.
.notifo {
  &-button {
    color: $color-button;

    & {
      position: relative;
    }

    svg {
      fill: $color-button;
    }

    &:hover {
      opacity: 0.8;
    }

    .notifo-seen-label {
      animation: fade-in 0.5s;
      background: $color-label;
      border: 0;
      border-radius: 10px;
      color: $color-label-text;
      display: inline-block;
      font-size: 10px;
      font-weight: normal;
      line-height: 16px;
      padding-left: 6px;
      padding-right: 6px;
      position: absolute;
      right: -8px;
      top: -5px;
    }
  }

  &-link {
    color: $color-link;

    &:hover {
      color: darken($color-link, 5%);
      text-decoration: underline;
      text-transform: none;
    }
  }

  &-modal {
    &-panel {
      animation: fade-in 0.4s;
      background: $color-background;
      border: 1px solid $color-border;
      border-radius: 2px;
      box-shadow: 0 4px 4px $color-shadow;
      margin-top: 5px;
      max-height: 450px;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 1.5rem 1.25rem;
      text-align: left;
      text-decoration: none;
      width: 450px;
    }
  }

  &-notification {
    & {
      border-bottom: 1px solid $color-border;
      border-top: 0;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      position: relative;
    }

    &-delete {
      @include absolute(0, 0, null, null);
      color: $color-error;
      cursor: pointer;
      display: none;

      svg {
        fill: $color-error;
      }
    }

    &:hover {
      .notifo-notification-delete {
        display: block;
      }
    }

    &:last-child {
      border-bottom: 0;
      border-top: 0;
      margin-bottom: 0;
      padding: 0;
    }

    &-row2 {
      display: flex;
      flex-direction: row;
      flex-grow: 0;
      position: relative;
    }

    &-right {
      overflow-x: hidden;
      overflow-y: inherit;
      flex-grow: 1;
    }

    &-left {
      max-width: 40px;
      min-width: 40px;
    }

    &-new {
      @include absolute(5px, null, null, -14px);
      @include size(10px, 10px);
      animation: fade-out 2s;
      background: $color-label;
      border: 0;
      border-radius: 20px;
      display: inline-block;
    }

    &-image-large {
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }

    &-image-small {
      margin-right: 10px;
      margin-top: 5px;

      img {
        width: 100%;
      }
    }

    &-subject {
      font-weight: bold;
      padding: 0;
      padding-right: 40px;
      position: relative;
      word-spacing: normal;
      word-wrap: break-word;
    }

    &-body {
      font-weight: normal;
      position: relative;
      word-spacing: normal;
      word-wrap: break-word;
    }

    &-link {
      @include truncate;
      font-size: 90%;
      font-weight: normal;
      margin: 0.25rem 0;
    }

    &-time {
      color: $color-empty;
      font-size: 80%;
      font-weight: normal;
      margin-top: 0.25rem;
    }

    &-confirm {
      @include truncate;
      background: $color-background;
      border: 1px solid darken($color-border, 10%);
      border-radius: 4px;
      font-size: 90%;
      font-weight: normal;
      margin-top: 0.25rem;
      padding: 0.5rem;

      &:hover {
        background: $color-border;
      }

      &:disabled {
        opacity: 0.5;
      }

      .notifo-loader {
        img {
          margin-right: 0.25rem;
        }
      }
    }
  }
}
