// sass-lint:disable leading-zero

$color-background-preview: #f7f7f7;

.preview-container {
  // Import here to avoid conflicts.
  @import './previews/Notifo';
  @import './previews/IOSPreview';
  @import './previews/AndroidPreview';
  @import './previews/DesktopMacOS';
  @import './previews/DesktopMacOSBS';
  @import './previews/DesktopWindows';
  background: $color-background-preview;
  padding: 2em;

  > * {
    margin: 2em auto;
  }
}
