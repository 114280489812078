@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
$width-of-preview: 25.3125rem;
$font-family: Roboto, 'Open Sans', Arial, Helvetica, sans-serif;
$color-background: #fefefe;
$color-background-button: #eee;
$color-background-button-hover: #9e9e9e66;
$color-white: #fff;
$color-text: #a7a7a7;
$color-text-header: #838383;
$color-text-button: #636363;
$color-text-title: #535353;
$color-text-app-name: #636363;
$color-text-message: #757575;
$color-shadow-1: #00000024;
$color-shadow-2: #00000033;
$color-shadow-3: #0000001f;

.android {
  font-family: $font-family;
  font-weight: 400;
  width: $width-of-preview;

  .main {
    background: $color-background;
    border: 0;
    border-radius: 0.1875rem;
    box-shadow: $color-shadow-1 0 0.125rem 0.125rem 0, $color-shadow-2 0 0.1875rem 0.0625rem -0.125rem, $color-shadow-3 0 0.0625rem 0.3125rem 0;
    cursor: pointer;
    padding: 0;

    .header {
      color: $color-text-header;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1rem 0.5rem;

      .app-meta {
        align-items: center;
        font-size: 0.75rem;
        line-height: 0.9375rem;

        .bell-icon {
          width: 1.0625rem;
        }

        .app-name {
          color: $color-text-app-name;
          margin: 0 0.25rem;
        }
      }
    }

    .arrow-icon {
      svg {
        width: 0.9375rem;
      }
    }

    .close-btn {
      cursor: pointer;
      font-size: 1rem;
      font-weight: normal;
      transform: scale(1.3, 1);
    }

    .section {
      display: flex;
      justify-content: space-between;
      padding: 0 1rem 1rem;
    }

    .title {
      color: $color-text-title;
      margin-bottom: 0.25rem;
    }

    .message {
      color: $color-text-message;
    }

    .header > * {
      display: flex;
    }

    .section > * {
      display: flex;
    }

    .short-description {
      flex-direction: column;
      font-size: 0.875rem;
      max-width: calc(100% - 3.125rem);

      .title,
      .message {
        line-height: 1.25rem;
      }
    }

    .image {
      flex: 1;

      img {
        max-width: 100%;
      }
    }

    .image-preview-small {
      height: 2.25rem;
      min-width: 2.25rem;
      width: 2.25rem;

      img {
        border-radius: 0;
        height: 100%;
        width: 100%;
      }
    }

    .icon {
      display: inline-block;
      margin-right: 0.3125rem;

      img {
        max-height: 1.25rem;
        max-width: 1.25rem;
      }
    }
  }

  &.expanded {
    .section {
      flex-direction: column;

      .image {
        margin-top: 0.625rem;
      }
    }
  }

  .buttons {
    background: $color-background-button;
    padding: 0.625rem 0.5rem;

    .button {
      color: $color-text-button;
      cursor: pointer;
      display: inline-block;
      font-size: 0.8125rem;
      line-height: normal;
      padding: 0.5rem;
      max-width: 33%;

      span {
        @include truncate;
      }

      &:hover {
        background: $color-background-button-hover;
      }
    }
  }
}
