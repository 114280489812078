@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
$width-of-preview: 29.5rem;
$font-family: Roboto, 'Open Sans', Arial, Helvetica, sans-serif;
$color-background: #f0f0f0;
$color-white: #fff;
$color-black: #000;
$color-text: #4d4d4d;
$color-shadow-1: #00000024;
$color-shadow-2: #00000033;
$color-shadow-3: #0000001f;

.desktop-macos {
  border-radius: 0.5rem;
  color: $color-black;
  font-family: $font-family;
  font-weight: 300;
  width: $width-of-preview;

  .main {
    background: $color-background;
    border: 0;
    border-radius: 0.8125rem;
    box-shadow: $color-shadow-1 0 0.1875rem 0.8125rem 0.0625rem,
      $color-shadow-2 0 0.1875rem 0.0625rem -0.125rem,
      $color-shadow-3 0 0.0625rem 0.3125rem 0;
    color: $color-text;
    cursor: pointer;
    display: flex;
    line-height: 1.375rem;
    padding: 0;

    > * {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .icon-container {
      padding-left: 0.625rem;
      width: 4.0625rem;

      .chrome {
        width: 3.125rem;
      }
    }

    .content-container {
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      letter-spacing: 0.03125rem;
      padding: 0.75rem 0.5rem;

      > * {
        max-width: 18.75rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .title {
        font-size: 1.21875rem;
        font-weight: 500;
      }

      .site {
        font-size: 1.16875rem;
        letter-spacing: 0.025rem;
      }

      .message {
        font-size: 1.1375rem;
      }
    }

    .app-logo-container {
      padding: 0.625rem;
      width: 5.625rem;

      img {
        width: 100%;
      }
    }

    .icon {
      img {
        width: 100%;
      }
    }
  }
}
